import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaEye, FaTrash } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import DataTable from 'react-data-table-component';

// InterviewResultModal remains the same
const InterviewResultModal = ({ interview, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-2xl mt-16 mb-8 max-h-[calc(100vh-8rem)] overflow-y-auto">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">{interview.candidateName} - {interview.jobTitle}</h2>
        <div className="space-y-4">
          <div>
            <p className="font-medium">Interview Date: <span className="font-normal">{interview.date}</span></p>
            <p className="font-medium">Overall Result: <span className={`font-normal ${interview.evaluation.isSuitable ? 'text-green-600' : 'text-red-600'}`}>
              {interview.evaluation.isSuitable ? 'Suitable' : 'Not Suitable'}
            </span></p>
            <p className="font-medium">Overall Score: <span className="font-normal">{interview.evaluation.overallScore}/10</span></p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Evaluation</h3>
            <p className="text-gray-700">{interview.evaluation.explanation}</p>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Strengths</h3>
            <ul className="list-disc list-inside">
              {interview.evaluation.strengths.map((strength, index) => (
                <li key={index} className="text-gray-700">{strength}</li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-medium mb-2">Areas for Improvement</h3>
            <ul className="list-disc list-inside">
              {interview.evaluation.areasForImprovement.map((area, index) => (
                <li key={index} className="text-gray-700">{area}</li>
              ))}
            </ul>
          </div>
        </div>
        <button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transition-colors duration-300 mt-6"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const TranscriptModal = ({ isOpen, onClose, transcript }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'unset'; };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-4xl mt-16 mb-8 max-h-[calc(100vh-8rem)] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Interview Transcript</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        <pre className="whitespace-pre-wrap text-sm text-gray-700 bg-gray-50 rounded p-4">
          {transcript || 'No transcript available'}
        </pre>
      </div>
    </div>
  );
};


// Delete Confirmation Modal remains the same
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        <p className="text-gray-600 mb-6">Are you sure you want to delete this interview record?</p>
        <div className="flex justify-end space-x-4">
          <button onClick={onClose} className="px-4 py-2 border rounded hover:bg-gray-100">
            Cancel
          </button>
          <button onClick={onConfirm} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const CompletedInterviews = inject('store')(observer(({ store }) => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');
  const [showTranscript, setShowTranscript] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState('');
 

  const fetchInterviews = async () => {
    try {
      setIsLoading(true);
      const response = await store.api.get('/user/completed');
      setInterviews(response.data);
    } catch (error) {
      console.error('Error fetching completed interviews:', error);
      store.notifications?.error?.('Failed to fetch interviews');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshJobList = useCallback(async () => {
    try {
      const response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
      store.notifications?.error?.('Failed to fetch job list');
    }
  }, [store]);

  useEffect(() => {
    refreshJobList();
    fetchInterviews();
  }, [refreshJobList]);

  const handleViewResult = async (interviewId) => {
    try {
      const response = await store.api.get(`/user/completed-interviews/${interviewId}`);
      setSelectedInterview(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching interview details:', error);
      store.notifications?.error?.('Failed to fetch interview details');
    }
  };
  const handleViewTranscript = async (interviewId) => {
    try {
      const response = await store.api.get(`/user/completed-interviews/${interviewId}`);
      setCurrentTranscript(response.data.transcript);
      console.log(response.data.transcript)
      setShowTranscript(true);
    } catch (error) {
      console.error('Error fetching interview details:', error);
      store.notifications?.error?.('Failed to fetch interview details');
    }
  };
  
  const handleDeleteClick = (interviewId) => {
    setInterviewToDelete(interviewId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      await store.api.delete(`/user/completed-interviews/${interviewToDelete}`);
      setInterviews(interviews.filter(interview => interview.id !== interviewToDelete));
      store.notifications?.success?.('Interview deleted successfully');
      setDeleteModalOpen(false);
      setInterviewToDelete(null);
    } catch (error) {
      console.error('Error deleting interview:', error);
      store.notifications?.error?.('Failed to delete interview');
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: 'Candidate',
        selector: row => row.candidateName,
        sortable: true,
      },
      {
        name: 'Job Title',
        selector: row => row.jobTitle,
        sortable: true,
      },
      {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
        format: row => new Date(row.date).toLocaleDateString(),
      },
      {
        name: 'Result',
        selector: row => row.result,
        sortable: true,
        cell: row => (
          <span className={`inline-block px-3 py-1 font-semibold ${
            row.result === 'Suitable' ? 'text-green-900 bg-green-200' : 'text-red-900 bg-red-200'
          } rounded-full`}>
            {row.result}
          </span>
        ),
      },
      {
        name: 'Score',
        selector: row => row.overallScore,
        sortable: true,
        cell: row => `${row.overallScore}/10`,
      },
      {
        name: 'Actions',
        cell: row => (
          <div className="flex items-center space-x-4">
            <button
              onClick={() => handleViewTranscript(row.id)}
              className="flex items-center text-blue-600 hover:text-blue-900 px-2 py-1"
            >
              <FaEye className="mr-2" />
              <span>Transcript</span>
            </button>
            <button
              onClick={() => handleViewResult(row.id)}
              className="flex items-center text-blue-600 hover:text-blue-900 px-2 py-1"
            >
              <FaEye className="mr-2" />
              <span>Score</span>
            </button>
            <button
              onClick={() => handleDeleteClick(row.id)}
              className="flex items-center text-red-600 hover:text-red-900 px-2 py-1"
            >
              <FaTrash className="mr-2" />
              <span>Delete</span>
            </button>
          </div>
        ),
        width: '300px', // Ensure enough space for all buttons
      },

    ],
    []
  );

  const filteredItems = interviews.filter(
    item => {
      const searchStr = filterText.toLowerCase();
      const matchesSearch = (
        (item.candidateName || '').toLowerCase().includes(searchStr) ||
        (item.jobTitle || '').toLowerCase().includes(searchStr)
      );
      const matchesJob = !selectedJob || item.jobId === parseInt(selectedJob);
      return matchesSearch && matchesJob;
    }
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Filter by candidate name or job title"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            className="p-2 border rounded mr-2 w-64"
          />
          <button 
            onClick={handleClear}
            className="px-4 py-2 border rounded hover:bg-gray-100"
          >
            Clear
          </button>
        </div>
        <div className="flex items-center">
          <select
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            className="p-2 border rounded w-48"
          >
            <option value="">All Jobs</option>
            {store.joblist.map(job => (
              <option key={job.jobid} value={job.jobid}>{job.jobname}</option>
            ))}
          </select>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, selectedJob, store.joblist]);

  return (
    <div className="px-4">
      <DataTable
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponent}
        persistTableHead
        customStyles={{
          header: {
            style: {
              display: 'none',
            },
          },
          subHeader: {
            style: {
              marginTop: '8px',
              marginBottom: '8px',
              padding: '0px',
            },
          },
          headRow: {
            style: {
              backgroundColor: '#F3F4F6',
              minHeight: '48px',
            },
          },
          rows: {
            style: {
              minHeight: '48px',
            },
          },
          cells: {
            style: {
              padding: '8px',
            },
          },
        }}
        dense
      />

      {isModalOpen && selectedInterview && (
        <InterviewResultModal
          interview={selectedInterview}
          onClose={() => setIsModalOpen(false)}
        />
      )}
     
     <TranscriptModal 
        isOpen={showTranscript}
        onClose={() => setShowTranscript(false)}
        transcript={currentTranscript}
      />
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setInterviewToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}));

export default CompletedInterviews;