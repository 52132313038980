import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import CompletedInterviews from './CompletedInterviews';
import PendingInterviews from './PendingInterviews';
import ScheduledInterviews from './ScheduledInterviews';

const VirtualInterviewWorkflow = inject('store')(observer(({ store }) => {
  const [activeTab, setActiveTab] = useState('scheduled');

  const renderContent = () => {
    switch (activeTab) {
      case 'completed':
        return <CompletedInterviews />;
      case 'pending':
        return <PendingInterviews />;
      case 'scheduled':
        return <ScheduledInterviews />;
      default:
        return null;
    }
  };

  const tabs = [
    { id: 'completed', label: 'Completed Interviews' },
    { id: 'pending', label: 'Pending Evaluation' },
    { id: 'scheduled', label: 'Scheduled Interviews' }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">Interview Management</h1>
          <Link 
            to="/virtual-interview/scheduling" 
            className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-md transition-colors"
          >
            <FaCalendarAlt className="mr-2" />
            Schedule Interview
          </Link>
        </div>

        <div className="bg-white rounded-lg shadow">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    py-4 px-6 font-medium text-sm border-b-2 transition-colors
                    ${activeTab === tab.id 
                      ? 'border-blue-600 text-blue-600' 
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                  `}
                >
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
          
          <div className="p-6">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}));

export default VirtualInterviewWorkflow;