import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FaEye, FaTrash } from 'react-icons/fa';
import { observer, inject } from 'mobx-react';
import DataTable from 'react-data-table-component';

// Progress Modal Component
const ProgressModal = ({ isOpen, progress }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-96">
        <h3 className="text-lg font-medium mb-4">Evaluating Interview</h3>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div 
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-center text-sm text-gray-600">
          {progress < 33 && "Fetching interview data..."}
          {progress >= 33 && progress < 66 && "Analyzing interview..."}
          {progress >= 66 && progress < 100 && "Generating evaluation..."}
          {progress === 100 && "Completing evaluation..."}
        </p>
      </div>
    </div>
  );
};

// Transcript Modal Component
const TranscriptModal = ({ isOpen, onClose, transcript }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    return () => { document.body.style.overflow = 'unset'; };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-4xl mt-16 mb-8 max-h-[calc(100vh-8rem)] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Interview Transcript</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        <pre className="whitespace-pre-wrap text-sm text-gray-700 bg-gray-50 rounded p-4">
          {transcript || 'No transcript available'}
        </pre>
      </div>
    </div>
  );
};

// Delete Confirmation Modal
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        <p className="text-gray-600 mb-6">Are you sure you want to delete this interview record?</p>
        <div className="flex justify-end space-x-4">
          <button onClick={onClose} className="px-4 py-2 border rounded hover:bg-gray-100">
            Cancel
          </button>
          <button onClick={onConfirm} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

const PendingInterviews = inject('store')(observer(({ store }) => {
  // States
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [evaluatingId, setEvaluatingId] = useState(null);
  const [showTranscript, setShowTranscript] = useState(false);
  const [currentTranscript, setCurrentTranscript] = useState('');
  const [loadingTranscript, setLoadingTranscript] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState(null);

  // Fetch job list and interviews
  const refreshJobList = useCallback(async () => {
    try {
      const response = await store.api.post('/user/JobDetails/view');
      store.joblist = response.data.map((job) => ({
        jobid: job.jobId,
        jobname: job.jobname,
        jobdescription: job.jobdescription,
      }));
    } catch (error) {
      console.error("Failed to fetch job list:", error);
      store.notifications?.error?.('Failed to fetch job list');
    }
  }, [store]);

  const fetchPendingInterviews = async () => {
    try {
      setIsLoading(true);
      const response = await store.api.get('/user/pending');
      setInterviews(Array.isArray(response.data.data) ? response.data.data : response.data);
    } catch (error) {
      console.error('Error fetching pending interviews:', error);
      store.notifications?.error?.('Failed to fetch interviews');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshJobList();
    fetchPendingInterviews();
  }, [refreshJobList]);

  // Handle transcript view
  const handleViewTranscript = async (interview_link) => {
    try {
      setLoadingTranscript(true);
      const response = await store.api.get(`/user/interview/TranscriptAndJD/${interview_link}`);
      if (response.data?.success && response.data?.finalData?.transcript) {
        setCurrentTranscript(response.data.finalData.transcript);
        setShowTranscript(true);
      } else {
        throw new Error('Failed to load transcript');
      }
    } catch (error) {
      console.error('Error fetching transcript:', error);
      store.notifications?.error?.('Failed to load transcript');
    } finally {
      setLoadingTranscript(false);
    }
  };

  // Handle evaluation
  const evaluateResult = async (interview_link) => {
    try {
      setEvaluatingId(interview_link);
      setShowProgress(true);
      setProgress(0);

      // Evaluation process steps...
      setProgress(20);
      const response = await store.api.get(`/user/interview/TranscriptAndJD/${interview_link}`);
      
      setProgress(40);
      const { jobDescription, transcript } = response.data.finalData;
      
      setProgress(60);
      const evaluationResponse = await store.api.post('/ai/evaluate-interview', {
        interviewHistory: transcript,
        jobDescription: jobDescription
      });
      
      setProgress(80);
      await store.api.post('/user/interview/save', {
        evaluation: evaluationResponse.data,
        interview_link: interview_link,
        transcript_data:transcript
      });
      
            
      setProgress(100);
      store.notifications?.success?.('Interview evaluated successfully');
      await fetchPendingInterviews();
      
    } catch (error) {
      console.error('Error during evaluation:', error);
      store.notifications?.error?.(error.message || 'Failed to evaluate interview');
    } finally {
      setTimeout(() => {
        setShowProgress(false);
        setProgress(0);
        setEvaluatingId(null);
      }, 1000);
    }
  };

  // Handle deletion
  const handleDeleteClick = (interviewId) => {
    setInterviewToDelete(interviewId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsLoading(true);
      await store.api.delete(`/user/pending-interviews/${interviewToDelete}`);
      await fetchPendingInterviews();
      store.notifications?.success?.('Interview deleted successfully');
      setDeleteModalOpen(false);
      setInterviewToDelete(null);
    } catch (error) {
      console.error('Error deleting interview:', error);
      store.notifications?.error?.('Failed to delete interview');
    } finally {
      setIsLoading(false);
    }
  };

  // DataTable columns
  const columns = useMemo(
    () => [
      {
        name: 'Candidate',
        selector: row => row.candidate_name || row.candidateName,
        sortable: true,
      },
      {
        name: 'Job Title',
        selector: row => row.job_name || row.jobTitle,
        sortable: true,
      },
      {
        name: 'Date',
        selector: row => row.timestamp || row.date,
        sortable: true,
        format: row => new Date(row.timestamp || row.date).toLocaleDateString(),
      },
      // {
      //   name: 'Duration (Min)',
      //   selector: row => row.duration,
      //   sortable: true,
      //   cell: row => Math.floor(row.duration) || '-',
      // },
      {
        name: 'Actions',
        cell: row => (
          <div className="flex items-center gap-4">
            <button
              onClick={() => handleViewTranscript(row.interview_link)}
              disabled={loadingTranscript}
              className="flex items-center px-3 py-1.5 text-blue-600 hover:text-blue-900 disabled:opacity-50 rounded transition-colors"
            >
              <FaEye className="mr-2" />
              <span>View</span>
            </button>
            <button
              onClick={() => evaluateResult(row.interview_link)}
              disabled={evaluatingId === row.interview_link}
              className="flex items-center px-3 py-1.5 text-blue-600 hover:text-blue-900 disabled:opacity-50 rounded transition-colors"
            >
              {evaluatingId === row.interview_link ? 'Evaluating...' : 'Evaluate'}
            </button>
          </div>
        ),
        width: '250px'
      }
    ],
    [evaluatingId, loadingTranscript]
  );

  // Filter logic
  const filteredItems = interviews.filter(
    item => {
      const searchStr = filterText.toLowerCase();
      const matchesSearch = (
        (item.candidate_name || item.candidateName || '').toLowerCase().includes(searchStr) ||
        (item.job_name || item.jobTitle || '').toLowerCase().includes(searchStr)
      );
      const matchesJob = !selectedJob || item.jobId === parseInt(selectedJob);
      return matchesSearch && matchesJob;
    }
  );

  // SubHeader component with filters
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Filter by candidate name or job title"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            className="p-2 border rounded mr-2 w-64"
          />
          <button 
            onClick={handleClear}
            className="px-4 py-2 border rounded hover:bg-gray-100"
          >
            Clear
          </button>
        </div>
        <div className="flex items-center">
          
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, selectedJob, store.joblist]);

  return (
    <div className="px-4">
      <DataTable
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponent}
        persistTableHead
        customStyles={{
          header: {
            style: {
              display: 'none',
            },
          },
          subHeader: {
            style: {
              marginTop: '8px',
              marginBottom: '8px',
              padding: '0px',
            },
          },
          headRow: {
            style: {
              backgroundColor: '#F3F4F6',
              minHeight: '48px',
            },
          },
          rows: {
            style: {
              minHeight: '48px',
            },
          },
          cells: {
            style: {
              padding: '8px',
            },
          },
        }}
        dense
      />

      <ProgressModal isOpen={showProgress} progress={progress} />
      
      <TranscriptModal 
        isOpen={showTranscript}
        onClose={() => setShowTranscript(false)}
        transcript={currentTranscript}
      />

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setInterviewToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}));

export default PendingInterviews;