import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { observer, inject } from 'mobx-react';
import DataTable from 'react-data-table-component';
import { FaEye, FaTrash } from 'react-icons/fa';

const ScheduledInterviews = inject('store')(observer(({ store }) => {
  const [interviews, setInterviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [interviewToDelete, setInterviewToDelete] = useState(null);

  const fetchScheduledInterviews = async () => {
    setIsLoading(true);
    try {
      const response = await store.api.get('/user/interviews/scheduled');
      setInterviews(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching scheduled interviews:', error);
      store.notifications?.error?.('Failed to load scheduled interviews');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (interviewId) => {
    setInterviewToDelete(interviewId);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      await store.api.delete(`/user/Interview/delete/${interviewToDelete}`);
      store.notifications?.success?.('Interview deleted successfully');
      // Remove the deleted interview from the local state
      setInterviews(prevInterviews => 
        prevInterviews.filter(interview => interview.interviewLink !== interviewToDelete)
      );
      setDeleteModalOpen(false); // Close the modal after successful deletion
    } catch (error) {
      console.error('Error deleting interview:', error);
      if (error.response?.status === 404) {
        store.notifications?.error?.('Interview not found');
      } else {
        store.notifications?.error?.('Failed to delete interview');
      }
    } finally {
      setIsDeleting(false);
      setInterviewToDelete(null); // Clear the interview to delete
    }
  };

  const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-sm w-full">
          <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
          <p className="text-gray-600 mb-6">Are you sure you want to delete this interview record?</p>
          <div className="flex justify-end space-x-4">
            <button 
              onClick={onClose} 
              className="px-4 py-2 border rounded hover:bg-gray-100"
              disabled={isDeleting}
            >
              Cancel
            </button>
            <button 
              onClick={onConfirm} 
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-red-300"
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchScheduledInterviews();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Job Title',
        selector: row => row.jobTitle,
        sortable: true,
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        cell: row => (
          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
            {row.status || 'Scheduled'}
          </span>
        ),
      },
      {
        name: 'Candidate',
        selector: row => row.candidateName,
        sortable: true,
      },
      {
        name: 'Email',
        selector: row => row.candidateEmail,
        sortable: true,
      },
      {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
        format: row => new Date(row.date).toLocaleDateString(),
      },
      {
        name: 'Actions',
        cell: row => (
          <button
            onClick={() => handleDeleteClick(row.interviewLink)}
            disabled={isDeleting}
            className="flex items-center text-red-600 hover:text-red-900 px-2 py-1 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FaTrash className="mr-2" />
            <span>Delete</span>
          </button>
        ),
        button: true,
        width: '100px',
      },
    ],
    [isDeleting]
  );

  const filteredItems = interviews.filter(
    item => {
      const searchStr = filterText.toLowerCase();
      return (
        (item.candidateName || '').toLowerCase().includes(searchStr) ||
        (item.jobTitle || '').toLowerCase().includes(searchStr) ||
        (item.candidateEmail || '').toLowerCase().includes(searchStr)
      );
    }
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <input
            type="text"
            placeholder="Filter by candidate name, email or job title"
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            className="p-2 border rounded mr-2 w-64"
          />
          <button 
            onClick={handleClear}
            className="px-4 py-2 border rounded hover:bg-gray-100"
          >
            Clear
          </button>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="px-4">
      <DataTable
        columns={columns}
        data={filteredItems}
        progressPending={isLoading}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponent}
        persistTableHead
        customStyles={{
          header: {
            style: {
              display: 'none',
            },
          },
          subHeader: {
            style: {
              marginTop: '8px',
              marginBottom: '8px',
              padding: '0px',
            },
          },
          headRow: {
            style: {
              backgroundColor: '#F3F4F6',
              minHeight: '48px',
            },
          },
          rows: {
            style: {
              minHeight: '48px',
            },
          },
          cells: {
            style: {
              padding: '8px',
            },
          },
        }}
        dense
      />
      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setInterviewToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}));

export default ScheduledInterviews;